@import "../../../styles/variables.scss";

.fixedBotCTA {
  background: $white;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 100;
  display: flex;
  color: $black;
  align-items: center;
  padding: 15px 12px;
  .ev {
    margin-right: 0.5rem;
  }
  .cta {
    font-size: rem(15px);
    line-height: rem(18px);
    font-weight: $fontWeightBold;
    letter-spacing: $letterSpacingTight;
    .lineOne {
      display: block;
      color: darken($grey, 15%);
    }
  }
  .btn {
    flex-grow: 1;
    text-align: right;
    .getTheApp {
      background: $citrine;
      color: $black;
      border: none;
      padding: 0.8rem 2rem;
      border-radius: 15rem;
      font-size: rem(15px);
      font-weight: $fontWeightBold;
      display: inline-block;
      letter-spacing: $letterSpacingTight;
      transition: all 0.5s ease;
      &:hover {
        background: lighten($citrine, 10%);
      }
    }
  }
}

@media (min-width: 992px) {
  .fixedBotCTA {
    display: none;
  }
}

@media (max-width: 768px) {
  .fixedBotCTA {
    left: 50%;
    max-width: 650px;
    transform: translateX(-50%);
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    padding-left: 0;

    .ev {
      margin: 0;
    }
  }
}
