@import "/styles/variables.scss";

.background {
  background-color: $white;
  padding-bottom: 30px;
  position: relative;
  z-index: 1;
}

.disclosure {
  font-size: rem(11px);
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: rem(13px);
}

.fluidContainer {
  margin: 0 1.5rem 1.5rem 1.5rem;
}

.expandLocationsModalLink {
  background: transparent;
  border: 0px;
  color: $pink !important;
  cursor: pointer;
  padding: 0px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

@include media-breakpoint-up(lg) {
  .fluidContainer {
    margin: 0 3rem 3rem;
  }
}
