@import "/styles/variables.scss";

.main {
  background-color: $blue;
  color: $white;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.container {
  position: relative;
  max-width: 1440px;
  max-height: 750px;
  margin: 0 auto;
}

.copy {
  padding: 203px 73px 203px 120px;
  transition: all 0.75s ease;
  opacity: 0;
}

.animate_copy {
  opacity: 1;
}

.heading {
  font-size: rem(60px);
  line-height: rem(60px);
  font-weight: $fontWeightBold;
  letter-spacing: -0.035em;
  color: $pink;
}

.subheading {
  font-size: rem(15px);
  line-height: rem(22px);
  margin: 25px 0 50px;
  padding-right: 30px;
}

.right_col {
  position: relative;
}

.image_container {
  position: absolute;
  width: 1224px;
  height: 1396px;
  top: -302px;
  right: -335px;
  transition: all 0.75s ease;
  transform: translateY(-70%);
}

.animate_image {
  transform: translateY(0);
}

@media (max-width: 1440px) {
  .copy {
    padding: 14.1vw 5vw 14.1vw 8.35vw;
  }

  .image_container {
    width: 85vw;
    height: 96.9vw;
    top: -20.95vw;
    right: -23.25vw;
  }
}

@media (max-width: 1200px) {
  .image_container {
    top: -16vw;
    right: -21vw;
  }
}

@media (max-width: 992px) {
  .copy {
    padding: 10vw 5vw 10vw 8.35vw;
  }

  .subheading {
    margin-bottom: 25px;
  }

  .image_container {
    top: -8vw;
    right: -19vw;
  }
}

@media (max-width: 767px) {
  .main {
    background-color: $white;
    padding: 15px;
  }

  .container {
    background-color: $blue;
    flex-direction: column;
    height: 1050px;
    max-height: none;
  }

  .copy {
    width: 100%;
    min-height: 200px;
    padding: 60px 25px 0;
    transition: none;
    opacity: 1;
  }

  .heading {
    font-size: rem(42px);
    line-height: rem(44px);
  }

  .subheading {
    margin: 25px 0 0 0;
    padding-right: 25px;
  }

  .download_button {
    display: none;
  }

  .image_container {
    position: absolute;
    width: 734.4px;
    height: 837.6px;
    top: 0;
    right: calc(50% - 395px);
    transition: none;
    transform: translateY(0);
  }
}

@media (max-width: 576px) {
  .copy {
    height: 300px;
  }

  .container {
    height: 1200px;
  }
}
