@import "/styles/variables.scss";

.section {
  --bs-gutter-x: 15px;
  background-color: $white;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  z-index: 1;
}

.card {
  background-color: $blue;
  color: $white;
  padding: 70px 25px 40px;

  &.missingDisclosure {
    padding-bottom: 70px;
  }
}

.heading {
  color: $pink;
  font-weight: 700;
  font-size: rem(40px);
  line-height: rem(42px);
  letter-spacing: -0.035em;
  margin-bottom: 40px;
}

.features {
  --bs-gutter-y: 15px;

  .featureIcon {
    margin-right: 30px;
    height: 60px;
    max-width: 60px;
  }

  .featureName {
    font-weight: 700;
    font-size: rem(20px);
    line-height: rem(21px);
    letter-spacing: -0.035em;
  }

  .featureDisclaimer {
    font-weight: 500;
    font-size: rem(11px);
    line-height: rem(13px);
    letter-spacing: -0.02em;
    color: $grey;
    margin-top: 5px;
  }
}

.disclosure {
  color: $white;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: -0.02em;
  margin-top: 40px;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;

  p {
    margin-bottom: 0px;
  }

  a {
    color: $pink;
    text-decoration: underline;

    &:active,
    &:hover {
      text-decoration: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .section {
    --bs-gutter-x: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .heading {
    font-size: rem(42px);
    line-height: rem(44px);
    text-align: center;
    margin-bottom: 60px;
  }

  .card {
    &.missingDisclosure {
      padding-bottom: 116px;
    }
  }

  .features {
    --bs-gutter-y: 70px;
    max-width: 944px;
    margin-left: auto;
    margin-right: auto;

    .feature {
      margin-left: auto;
      margin-right: auto;
      max-width: 160px;
    }

    .featureIcon {
      height: 80px;
      margin-right: 0px;
      max-width: 80px;
    }

    .featureName {
      font-weight: 700;
      text-align: center;
      margin-top: 20px;
    }

    .featureDisclaimer {
      font-size: rem(13px);
      line-height: rem(15px);
      letter-spacing: -0.02em;
      margin-top: 4px;
      text-align: center;
    }
  }

  .disclosure {
    margin-top: 60px;
  }
}
