@import "/styles/variables.scss";

.banner {
  --bs-gutter-x: 15px;
  background-color: $black;
  color: $white;
  padding-bottom: 15px;
  padding-top: 15px;
}

.imageContainer {
  display: flex;
  max-width: 30px;
}

.header {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.textRow {
  --bs-gutter-x: 0px;
  --bs-gutter-y: 10px;
  align-items: center;
  margin-left: 15px;
}

.button {
  color: $pink;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-decoration: underline;

  &:hover {
    color: $pink;
    text-decoration: none;
  }
}

@include media-breakpoint-up(lg) {
  .container {
    max-width: 990px !important;
  }
}
