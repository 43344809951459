@import "/styles/variables.scss";

.section {
  align-items: center;
  background-color: #000000;
  color: $white;
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.learnMore {
  position: fixed;
  left: 50%;
  bottom: 8rem;
  transform: translateX(-50%);
  background: none;
  color: $white;
  border: none;
  display: inline-block;
  text-align: center;
  z-index: 1;
  &:hover {
    color: $white;
  }
  .lmText {
    margin-bottom: 0.5rem;
  }
}

.backgroundImage {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  will-change: transform, opacity;
}

.contentContainer {
  left: 0px;
  right: 0px;
  will-change: position;
}

.heading {
  font-weight: $fontWeightBold;
  font-size: rem(42px);
  line-height: rem(44px);
  letter-spacing: -0.035em;
  margin-bottom: 25px;
}

.subheading {
  font-size: rem(18px);
  font-weight: $fontWeightMedium;
  letter-spacing: -0.01em;
  line-height: rem(24px);
  margin-bottom: 40px;
}

@include media-breakpoint-up(md) {
  .background {
    height: 750px;
  }

  .content {
    margin-left: auto;
    margin-right: auto;
    max-width: rem(704px);
    text-align: center;
  }

  .heading {
    font-size: rem(60px);
    line-height: rem(60px);
  }
}

@include media-breakpoint-up(lg) {
  .learnMore {
    bottom: 1.5rem;
  }
}
