@import "/styles/variables.scss";

.homeContent {
  --bs-gutter-x: 40px;
  -webkit-font-smoothing: antialiased;
  background-color: #000000;
  color: $black;
  line-height: $lineHeight_normal;
  letter-spacing: normal;
  text-align: left;
}

.reorderDigital {
  position: relative;
  z-index: 1;
}

@include media-breakpoint-up(md) {
  .reorderDigital {
    background: $white;
    padding: rem(60px);
  }
}

@include media-breakpoint-up(lg) {
  .homeContent {
    --bs-gutter-x: 60px;
  }
}
