@import "../../../styles/variables.scss";

.section {
  background: #000000;
  padding: rem(60px) 0;
  color: $white;
  position: relative;
  z-index: 1;
}

.title {
  font-size: rem(42px);
  line-height: rem(44px);
  letter-spacing: $letterSpacingTight;
  font-weight: $fontWeightBold;
  margin-bottom: 1.5rem;
}

.content {
  font-size: rem(15px);
  line-height: rem(22px);
  margin-bottom: 2rem;
}

.logo {
  width: 50%;
  text-align: left;
}

.partnerFeatures {
  border-top: 1px solid rgba($white, 0.4);
  padding-top: rem(35px);
  margin-top: rem(50px);
}

.featureHeading {
  font-size: rem(25px);
  line-height: rem(25px);
  font-weight: $fontWeightBold;
  margin-bottom: 1.5rem;
}

@include media-breakpoint-up(md) {
  .section {
    padding-top: rem(120px);
  }
  .logo {
    width: calc(100% / 4);
    text-align: center;
  }
  .partnerFeatures {
    padding-top: rem(50px);
  }
  .featureHeading {
    text-align: center;
  }
}

@include media-breakpoint-up(lg) {
  .section {
    text-align: center;
  }
  .title {
    max-width: 685px;
    margin: 0 auto 1.5rem auto;
  }
  .content {
    max-width: 705px;
    margin: 0 auto 2rem auto;
  }
  .logo {
    width: calc(100% / 8);
    padding: 0 1rem;
  }
}
