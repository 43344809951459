@import "../../../styles/variables.scss";

.videoSection {
  letter-spacing: $letterSpacingTighter;
  color: $black;
  padding: rem(70px) rem(25px) rem(40px) rem(25px);
  text-align: left;
}

.hpVideoSection {
  letter-spacing: $letterSpacingTighter;
  color: $black;
  padding: 0;
  text-align: left;
}

.title {
  font-size: rem(42px);
  line-height: rem(44px);
  font-weight: $fontWeightBold;
  margin-bottom: 2rem;
}

.content {
  font-size: rem(15px);
  line-height: rem(22px);
  margin: 0 auto 4rem auto;
  letter-spacing: $letterSpacingNormal;
}

.mobileVideo {
  background: $black;
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;
  position: relative;
}

.mobileVideoPlayer {
  pointer-events: none;
}

.playButton {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: none;
  background: none;
}

.desktopVideo {
  background: $teal;
  position: relative;
  overflow: hidden;
  .desktopVideoPrev {
    pointer-events: none;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .titleSmall {
    position: absolute;
    left: 50%;
    top: 6.75rem;
    transform: translateX(-50%);
    font-size: rem(55px);
    line-height: rem(55px);
    color: $purple;
    font-weight: $fontWeightBold;
    z-index: 2;
    text-align: right;
  }
  .titleLarge {
    position: absolute;
    right: -20px;
    top: 16rem;
    font-size: rem(180px);
    line-height: rem(130px);
    color: $purple;
    z-index: 2;
    font-weight: $fontWeightBold;
  }
}

.embedContainer {
  position: relative;
  padding-bottom: 56.25%;
  height: 37vw;
  overflow: hidden;
  z-index: 0;
  width: 100%;
}

.emVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  iframe {
    width: 100%;
  }
}

@include media-breakpoint-up(md) {
  .videoSection {
    margin-bottom: rem(80px);
  }
}

@include media-breakpoint-up(lg) {
  .title {
    font-size: rem(42px);
    line-height: rem(44px);
    text-align: center;
    margin-bottom: 2rem;
  }
  .content {
    max-width: 729px;
    text-align: center;
  }
  .desktopVideo {
    width: calc(100% - rem(120px));
    height: 100%;
    margin: 0 rem(60px);
  }
}
