@import "/styles/variables.scss";

.background {
  background-color: $white;
  position: relative;
  z-index: 1;

  .contactForm {
    padding-bottom: 30px;
    padding-top: 45px;
  }
}

@include media-breakpoint-up(md) {
  .background {
    .contactForm {
      padding-bottom: 60px;
      padding-top: 0px;
    }
  }
}
