@import "../../../styles/variables.scss";

.section {
  background: $purple;
  color: $white;
  padding: rem(60px) 0;
  letter-spacing: $letterSpacingTight;
  position: relative;
  z-index: 1;
}

.title {
  font-size: rem(42px);
  line-height: rem(44px);
  font-weight: $fontWeightBold;
  color: $pink;
  max-width: 250px;
  margin-bottom: 1rem;
}

.subtitle {
  font-size: rem(15px);
  line-height: rem(22px);
  margin-bottom: 3rem;
  max-width: 500px;
}

.mobileImage {
  margin-left: rem(-60px);
  max-width: 500px;
}

@include media-breakpoint-up(lg) {
  .section {
    text-align: center;
    padding: rem(90px) 0 rem(60px) 0;
  }
  .title {
    font-size: rem(60px);
    line-height: rem(60px);
    max-width: 100%;
  }
  .subtitle {
    max-width: 661px;
    margin: 0 auto 4rem auto;
  }
}
